#home ion-content {
  --background: url('../images/conffetti.jpg') no-repeat center center / cover;
}

#home .logo {
  display: flex;
  align-items: center;
  margin-top: 111px;
  justify-content: center;
  flex-direction: column;
}

#home .logo p {
  color: #ffcc66;
}

#home ion-card {
  --color: #000;
  margin: 47px 0;
}

#home ion-card-header {
  padding-top: 33px;
}

#home ion-card-content {
  padding: 11px 33px 22px;
}

#home ion-card-title {
  --color: #000;
  font-size: 22px;
  text-align: center;
}
