#edit-birthday .primary-button {
  --background: #0099cc;
}

#edit-birthday ion-item.pink-line {
  --border-color: #ff66cc;
}

#edit-birthday ion-item.blue-line {
  --border-color: #33ccff;
}
