#tabs ion-tab-button {
  --background: #000;
  --color: #fff;
  --color-selected: #ff66cc;
  --padding-top: 11px;
  --padding-bottom: 11px;
}

ion-tab-bar {
  --border: #000;
}
/* 
#tabs ion-label {
  margin-bottom: 0px;
} */
