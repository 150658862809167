#login .logo {
  margin-top: 33px;
  margin-bottom: 55px;
}

#login .login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#login .sign-in-button {
  margin-top: 55px;
}

#login .forgot-password-button {
  font-size: 16px;
  color: #ff66cc;
  text-transform: none;
}
