.landing-page {
  --background: #33ccff;
}

.landing-page ion-grid,
.landing-page ion-row,
.landing-page ion-col {
  height: 100%;
}

.landing-page .flex-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.landing-page .logo {
  display: flex;
  align-items: center;
  margin-top: 111px;
  justify-content: center;
  flex-direction: column;
}

.landing-page .logo p {
  color: #000;
  margin-block-end: 0;
}

.landing-page .logo p.welcome {
  color: #ff66cc;
}

.landing-page .footer a {
  color: #fff;
  text-decoration: none;
}

.landing-page .footer p {
  color: #000;
}
