.primary-button {
  --background: #ff66cc;
  color: #fff;
  text-transform: initial;
  --padding-start: 33px;
  --padding-end: 33px;
  --border-radius: 5px;
  height: 47px;
  font-weight: normal;
  font-size: 17px;
}

.input-box {
  border-bottom: 1px lightgray solid;
}
